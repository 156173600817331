import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject, Subscription } from 'rxjs';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { Router, NavigationExtras, ActivatedRoute, Params } from '@angular/router';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import * as moment from 'moment';

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.css']
})
export class OfferComponent implements OnInit {
  warehouseid: Number = null;
  statusid: Number = null;
  warehouseColl: any = [];
  offerallColl: any = [];
  offerColl: any = [];

  isadd: boolean = false;
  isedit: boolean = false;
  isdelete: boolean = false;
  isview: boolean = false;
  isprint: boolean = false;
  isexport: boolean = false;

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Offer";

  filterby: String = "ACTIVE";


  private gridApi;
  private gridColumnApi;

  columnDefs = [
   
    { headerName: 'Offer Code', field: 'offercode', sortable: true, filter: true },
    { headerName: 'Offer Name', field: 'offername', sortable: true, filter: true },
    { headerName: 'Offer Type', field: 'offertype', sortable: true, filter: true },
    { headerName: 'Offer From', field: 'offerfrom', sortable: true, filter: true, valueFormatter: this.fnMomentDateFormatter },
    { headerName: 'Offer To', field: 'offerto', sortable: true, filter: true, valueFormatter: this.fnMomentDateFormatter },
    { headerName: 'Update On', field: 'modifiedon', sortable: true, filter: true, valueFormatter: this.fnMomentDateTimeFormatter },
  ]

  gridOptions = {
    rowSelection: 'single',
    rowHeight: 40,
    columnDefs: this.columnDefs,
    defaultColDef: {
      resizable: true,
      flex: 1,
      width: 100,
      minWidth: 100,
      enableValue: false,
      enableRowGroup: true,
      enablePivot: true,
      sortable: true,
      filter: true,
    },

  };


  _routerSub = Subscription.EMPTY;
  _routerLoginSub = Subscription.EMPTY

  constructor(private appService: DhukanDataService, public router: Router, private route: ActivatedRoute,
    private confirmationDialogService: ConfirmationDialogService, private toastr: ToastrService, private loginService: LoginDataService) {
    this._routerSub = this.route.queryParams.subscribe(params => {


      if (params.filterby !== undefined) {
        this.filterby = params.filterby
      }
      if (params.warehouseid !== undefined) {
        this.warehouseid = Number(params.warehouseid)
      }
      if (params.statusid !== undefined) {
        this.statusid = Number(params.statusid)
      }
    }); this.fnServiceChanges();
  }

  //User Check
  fnServiceChanges() {
    this._routerLoginSub = this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.fnUserFailedForm();
      }
    });
  }





  ngOnInit() {
    try {


      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    this.fngetWarehouse();
    //  this.fngetOfferListView();     
  }

  fnCheckPrivilage() {
    this.isadd = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE');
    this.isedit = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER');
    this.isdelete = this.loginService.fnCheckUserRoleAction(this.formname, 'DELETE');
    this.isview = this.loginService.fnCheckUserRoleAction(this.formname, 'VIEW');
    this.isprint = this.loginService.fnCheckUserRoleAction(this.formname, 'PRINT');
    this.isexport = this.loginService.fnCheckUserRoleAction(this.formname, 'EXPORT');
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }


  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {

    if (this._routerSub !== undefined) {
      this._routerSub.unsubscribe();
    }

    if (this._routerLoginSub !== undefined) {
      this._routerLoginSub.unsubscribe();
    }
  }

  //Grid
  fnGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }


  //Event  
  fnActionEditClick() {
    if (!this.gridApi) {
      return;
    }

    if (this.gridApi.getSelectedRows().length > 0) {
      var data = this.gridApi.getSelectedRows()[0];
      this.fnEditOffer(data.offerid);
    }
  }

  fnActionDeleteClick() {
    if (!this.gridApi) {
      return;
    }

    if (this.gridApi.getSelectedRows().length > 0) {
      var data = this.gridApi.getSelectedRows()[0];
      this.fnDeleteConfirm(data.offerid);
    }
  }

  fnActionExportClick() {
    const params = {
      columnGroups: true,
      allColumns: true,
      fileName: 'offerlist',
    };
    this.loginService.fnExportdataCsv(params, this.gridApi)
  }

  fngetWarehouse() {

    var data = {
      'warehouseid': 0,

    }
    this.appService.getWarehouse(data)
      .subscribe(
        (res) => {

          if (res.json().status == 200) {
            this.warehouseColl = res.json().result;
            console.log(this.warehouseColl)
            if (this.warehouseColl.length > 0 && !this.warehouseid) {
              this.warehouseid = this.warehouseColl[0].warehouseid;
              //  this.fngetReportListView()
            }

            this.fngetOfferListView();
          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }

        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }


  fngetOfferListView() {

    if (this.showProgress) {
      return;
    }

    this.fnShowProgress();
    var data = {
      warehouseid: this.warehouseid
    }
    this.appService.getOfferListView(data)
      .subscribe(
        (res) => {
          this.fnHideProgress();
          if (res.json().status == 200) {
            this.offerallColl = res.json().result;

            this.offerColl = [];

            for (let abo of this.offerallColl) {
              if (this.filterby === 'ACTIVE') {
                if (abo.ischeck === 1) {
                  this.offerColl.push(abo)
                }
              }
              else if (this.filterby === 'INACTIVE') {
                if (abo.ischeck === 0) {
                  this.offerColl.push(abo)
                }
              }
              else {
                this.offerColl.push(abo)
              }
            }


            this.fnSetDataTable();
            this.fnCurrentRouteChange()
          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }
        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }

  fnCurrentRouteChange() {

    const queryParams: Params = {
      'filterstatus': this.filterby,
      'warehouseid': this.warehouseid,
      'statusid': this.statusid,
    };

    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: queryParams,

      });
  }
  fnAddOffer() {
    try {


      this.router.navigate(['/AddOffer']);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }



  fnEditOffer(deptid) {
    try {
      let navigationExtras: NavigationExtras = {
        queryParams: {
          'id': deptid,
          'warehouseid': this.warehouseid,
          'filterby': this.filterby,
        }
      }

      this.router.navigate(['/AddOffer'], navigationExtras);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  public fnDeleteConfirm(deptid) {
    try {
      this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete Offer?')
        .then((confirmed) => {
          if (confirmed) {
            this.fnDeleteOffer(deptid)
          }
        }
        );
      //.catch(() => );
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  private fnDeleteOffer(deptid) {
    var data = {
      'offerid': deptid,
    }
    this.appService.fnDeleteOffer(data)
      .subscribe(
        (res) => {
          if (res.json().status === 200) {
            this.toastr.success('', " The Offer has been deleted successfully.");

            this.fnInsertActivityLog('DELETE', deptid, this.fnGetDescription(deptid));

            this.fngetOfferListView();
          } else {
            this.toastr.error('', res.json().err_field);
          }
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  private fnSetDataTable() {

    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
    }

  }



  fnGetDescription(id) {
    const index = this.offerColl.findIndex(sku => sku.offerid === id);

    if (index) {
      return this.offerColl[index].offername;
    }
    else {
      return "";
    }

  }


  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      this.appService.fnInsertActivity(data).subscribe(
        (res) => {
        },
        (err) => {

        });

    } catch {

    }
  }


  fnMomentDateFormatter(params) {
    return moment(params.value).utcOffset('+02:30', false).format('DD-MM-YYYY');//hh:mm a  
  }

  fnMomentDateTimeFormatter(params) {
    return moment(params.value).utcOffset('+02:30', false).format('DD-MM-YYYY hh:mm a');//
  }

  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }

  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }




}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';

@Component({
  selector: 'app-editorders',
  templateUrl: './editorders.component.html',
  styleUrls: ['./editorders.component.css']
})
export class EditordersComponent implements OnInit {

  @Input() doctype: string;
  @Input() mode: string;
  @Input() req:{};
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() msgboxtype: string;
  @Output() emitData = new EventEmitter();
  specificationColl:any=[];

  @Input() productBO: any = {};
  @Input() orderNo: string = '';

  @Input() paytype: string = "";

  @Input() editorValue: string = '';
 

  @Input() orderid: number = 0;
  @Input() orderdetailsid: number = 0;
  @Input() qty: number = 0;
  @Input() productname: string = "";
  @Input() oldqty: number = 0;
  
  ordersubmitted:boolean;
  ordersuccessfully:boolean;

  constructor(private activeModal: NgbActiveModal,private appService: DhukanDataService,private loginService:LoginDataService) { }

  ngOnInit() {
   
  }
  
  
  
  public decline() {
    this.activeModal.close(false);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }


  fnQtyChangeOrderProduct(){
    this.ordersubmitted=true;
    this.ordersuccessfully=false;

    if(!this.qty){
      this.qty=0;
    }


    //Validate
    if(this.qty>0){  

      var data = {
        'orderid':this.orderid,            
        'orderdetailsid':this.orderdetailsid,                          
        'cancelledby':this.loginService.fnGetModUserId(),    
        'qty':this.qty,                     
      }

      this.appService.fnSetOrderItemQtyChange(data)
      .subscribe(
        (res)=>{     
                           
          this.emitData.emit(res.json().result[0]);  
          this.activeModal.close(true); 
        },
      (err)=>{          
        //this.toastr.error('Error', err);
      })
  
 
    }else{
      this.qty=0;
    }


  }
  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    var value = event.target.value;
  
    // Check if the user is trying to enter a decimal point
    if (charCode == 46) {
      // Check if the value already contains a decimal point
      if (value.indexOf('.') != -1) {
        event.preventDefault();
        return false;
      }
    }
  
    // Check if the user is trying to enter a digit
    if (charCode >= 48 && charCode <= 57) {
      // Check if the value already contains 3 digits after the decimal point
      if (value.indexOf('.') != -1 && value.split('.')[1].length >= 3) {
        event.preventDefault();
        return false;
      }
    }
  
    // Only Numbers 0-9 and decimal point
    if ((charCode < 48 || charCode > 57) && charCode != 46) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }


}

import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cancelledproduct',
  templateUrl: './cancelledproduct.component.html',
  styleUrls: ['./cancelledproduct.component.css']
})
export class CancelledproductComponent implements OnInit {

  mainColl:any=[];
  customerColl: any = [];
  
  warehouseid: number = null;
  warehouseColl: any = [];

  isadd:boolean=false;
  isedit:boolean=false;
  isdelete:boolean=false;
  isview:boolean=false;
  isprint:boolean=false;
   isexport:boolean=false; 
  
  isloading:boolean=false;
  isprivilage:boolean=false;  
  showProgress:boolean=false;
  formname:String="SalesByProduct";

  fromdate:any=null;
  todate:any=null;
  isshowfromdate: boolean = false;
  customerid:any = null;
  
  private gridApi;
  private gridColumnApi;
  pinnedBottomRowData;

  columnDefs = [
    { headerName: 'OrderNo', field: 'orderno', resizable: true ,sortable: true, filter: true },
    {
      headerName: 'OrderDate', field: 'orderdate', resizable: true ,sortable: true, filter: true,
      cellRenderer: (data) => {
        return this.fnMomentDateTimeFormatter(data)
      }
    },
    { headerName: 'Customer', field: 'customername', resizable: true, sortable: true, filter: true },
    { headerName: 'Mobile', field: 'mobile', resizable: true , sortable: true, filter: true },
    { headerName: 'Barcode', field: 'sku', resizable: true , sortable: true, filter: true },
    { headerName: 'Productname', field: 'productname', resizable: true, sortable: true, filter: true },
    { headerName: 'Department', field: 'deptname', resizable: true, sortable: true, filter: true },
    { headerName: 'Subcategory', field: 'subcategoryname', resizable: true, sortable: true, filter: true },
    { headerName: 'Brand', field: 'brandname', resizable: true , sortable: true, filter: true },
    { headerName: 'Uom', field: 'uom', resizable: true , sortable: true, filter: true },
    { headerName: 'Measure', field: 'uomqty', resizable: true ,sortable: true, filter: true },
    { headerName: 'Order Qty', field: 'qty',resizable: true , sortable: true, filter: true, valueFormatter:  this.fnFormatDecimal },    
    { headerName: 'Price Incl VAT', field: 'price',resizable: true , sortable: true, filter: true,valueFormatter: this.fnFormatDecimal },
    { headerName: 'SubTotal Incl VAT', field: 'subtotal',resizable: true , sortable: true, filter: true,valueFormatter:  this.fnFormatDecimal  },
    { headerName: 'CancelledBy', field: 'cancelledbyname',resizable: true , sortable: true, filter: true },
    { headerName: 'Reason', field: 'cancelleddesc',resizable: true , sortable: true, filter: true },
    {
      headerName: 'CancelledOn', field: 'cancelledon',resizable: true , sortable: true, filter: true,
      cellRenderer: (data) => {
        return this.fnMomentDateTimeFormatter(data)
      }
    },    
  ]

  _routerSub = Subscription.EMPTY;
  _routerLoginSub = Subscription.EMPTY
  
  constructor( private appService: DhukanDataService,public router: Router,private route: ActivatedRoute,
    private confirmationDialogService: ConfirmationDialogService, private datePipe: DatePipe,
    private toastr: ToastrService, private loginService: LoginDataService) {
      this.fnServiceChanges();
    this._routerSub = this.route.queryParams.subscribe(params => {

      if (params.warehouseid !== undefined) {
        this.warehouseid = Number(params.warehouseid);
      }
  
    });
  
  }
 //User Check
 fnServiceChanges(){
  this._routerLoginSub = this.loginService.serviceloginChange.subscribe((value) => {
        if(this.loginService.fnCheckUser()){                
            this.fnCheckPrivilage();
            if(this.isview){
              this.isloading=true;
              this.fnOnInitForm();
            }
            else{
              this.fnUserFailedForm();
            }    
        }
        else{
          this.fnUserFailedForm();
        }
  });
  }  


  ngOnInit() {
    try{
      
      this.fromdate=new Date();
      this.todate=new Date();
      
     
      if(this.loginService.fnCheckUser()){         
        this.fnCheckPrivilage();
        if(this.isview){
          this.isloading=true;
          this.fnOnInitForm();
        }
        else{
          this.fnUserFailedForm();
        }          
      }
      else{
        this.loginService.fnGetUserRoleList();
      }
      
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm(){
    this.fngetWarehouse()
    //this.fngetReportListView(); 
    this.fngetCustomer();
  }

  fnCheckPrivilage(){
    this.isadd=this.loginService.fnCheckUserRoleAction(this.formname,'CREATE');
    this.isedit=this.loginService.fnCheckUserRoleAction(this.formname,'ALTER');
    this.isdelete=this.loginService.fnCheckUserRoleAction(this.formname,'DELETE');
    this.isview=this.loginService.fnCheckUserRoleAction(this.formname,'VIEW');
    this.isprint=this.loginService.fnCheckUserRoleAction(this.formname,'PRINT');
    this.isexport=this.loginService.fnCheckUserRoleAction(this.formname,'EXPORT');
  }

  fnUserFailedForm(){
    this.isprivilage=true;
  }


  ngAfterViewInit(): void {
 
  }

  ngOnDestroy(): void {
   
    if (this._routerSub !== undefined) {
      this._routerSub.unsubscribe();
    }
  
    if (this._routerLoginSub !== undefined) {
      this._routerLoginSub.unsubscribe();
    }
  }
  
  fngetWarehouse() {

    var data = {
      'warehouseid': 0,
      'modifiedby': this.loginService.fnGetModUserId(),
    }
    this.appService.getWarehouse(data)
      .subscribe(
        (res) => {
          
          if (res.json().status == 200) {
            this.warehouseColl = res.json().result;
            
            this.fngetReportListView()
          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }

        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }
  fngetCustomer() {
 
    var data = {
      'customerid': 0,
    }
    this.appService.getCustomerAdmin(data)
      .subscribe(
        (res) => {

          this.fnHideProgress();
          if (res.json().status == 200) {
            this.customerColl = res.json().result;            
          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }


        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }

  
  fngetReportListView() {   
    if(this.showProgress){
      return;
    }

    this.fnShowProgress();
    var fromdate = null;
    if (!this.isshowfromdate) {
      fromdate = this.fnFormatDatetime(this.fromdate);
    }



    var data = {
      'warehouseid':this.warehouseid, 
            'fromdate':fromdate, 
            'todate':this.fnFormatDatetime(this.todate), 
            'formname': 'CANCELLEDPRODUCT',       
            'customerid':this.customerid,
            'modifiedby': this.loginService.fnGetModUserId(),
      }
      this.appService.getReportSalesView(data)
          .subscribe(
            (res)=>{      
              this.fnHideProgress();                   
              if(res.json().status==200){                          
                this.mainColl = res.json().result[0];
                this.fnCurrentRouteChange();
                this.pinnedBottomRowData = res.json().result[1];

                this.fnSetDataTable();
             
              }
              else if(res.json().status==400){                          
                this.toastr.error('Error', res.json().err_field);
              }                          
          },
            (err)=>{          
              this.fnHideProgress();       
              this.toastr.error('Error', err);
            })
  }

 
  fnCurrentRouteChange() {

    const queryParams: Params = {
      //'filterstatus': this.filterby,
      'warehouseid': this.warehouseid,
    };

    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: queryParams,

      });
  }
  
  //Grid
  fnGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  


  private fnSetDataTable(){
   
    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();

      var allColIds = this.gridColumnApi.getAllColumns()
          .map(column => column.colId);
          this.gridColumnApi.autoSizeColumns(allColIds);

      // If you want to resize all columns
      this.gridColumnApi.autoSizeAllColumns();


    }
  
  }

  public fnSearchDatatable(value){    
   
  }


fnFormatDecimal(params) {
    if (params.value) {
     return params.value.toFixed(2)
    }    
}
  

  
  
fnMomentDateFormatter(params) {
  if (params.value) {
    return moment(params.value).utcOffset('+02:30',false).format('DD-MM-YYYY');//hh:mm a  
  }
  else {
    return '';
  }
}

fnMomentDateTimeFormatter(params) {
  if (params.value) {
    return moment(params.value).utcOffset('+02:30',false).format('DD-MM-YYYY hh:mm a');//  
  }
  else {
    return '';
  }
  
}

  
  
fnFormatDatetime(dt){
  if(dt){
    if(dt===""){
      return null
    }
    else{
      return this.datePipe.transform(dt,"yyyy-MM-dd")  
    }
    
    }else {
    return null
  }
  
}

fnConvertDatetime(dt){
  if(dt){
    if(dt===""){
      return null
    }
    else{
      

      var dateParts = dt.split("-"); 

       var sdate =new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2]+1); 

       sdate.setMinutes(sdate.getMinutes() + sdate.getTimezoneOffset());

       
       
       return sdate;
    }
    
    }else {
    return null
  }
 
}


      
fnPrintData(){
  try{
  
                      
  }catch(ex){
    this.fnShowErrorMsg(ex);
  }    
}

fnExportData(){
  try{
    const params = {
      columnGroups: true,
      allColumns: true,
      fileName: 'CancelledProduct',
      //columnSeparator: document.querySelector("#columnSeparator").value
    };
    this.loginService.fnExportdataCsv(params,this.gridApi)
                          
  }catch(ex){
    this.fnShowErrorMsg(ex);
  }    
}

  fnCheckFromDate() {
   
 } 
  
private fnShowProgress(){
  this.showProgress=true;
}
private fnHideProgress(){
  this.showProgress=false;
}


  private  fnShowErrorMsg(ex){
    this.toastr.warning('Internal Error', ex);       
  }



}

import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject, Observable, Subscription } from 'rxjs';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { Router, NavigationExtras, Params, ActivatedRoute } from '@angular/router';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { ProductfindComponent } from '../productfind/productfind.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdddeliveryoptionComponent } from './adddeliveryoption/adddeliveryoption.component';
import { FibaseDataService } from 'src/app/services/dhukan/fibase-data.service';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { DeliveryboyaddressmapComponent } from '../deliveryboy/deliveryboyaddressmap/deliveryboyaddressmap.component';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';


@Component({
  selector: 'app-deliveryorder',
  templateUrl: './deliveryorder.component.html',
  styleUrls: ['./deliveryorder.component.css']
})
export class DeliveryorderComponent implements OnInit {
  statusid: number = 0;
  warehouseid: Number = null;
  warehouseColl: any = [];

  deliveryorderColl: any = [];

  deliveryorderid: number = 0
  orderid: number = 0

  isadd: boolean = false;
  isedit: boolean = false;
  isdelete: boolean = false;
  isview: boolean = false;
  isprint: boolean = false;
  isexport: boolean = false;

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Shipment";

  isorderassign: boolean = false;
  isorderreadytopick: boolean = false;
  isorderpick: boolean = false;
  isorderdeliver: boolean = false;

  filterby: String = "PENDINGSHIPMENT";
  isselectlist: boolean = false;
  fromdate: any = null;
  todate: any = null;

  unassigncount: number = 0;
  assigncount: number = 0;
  readytopickcount: number = 0;
  pickedcount: number = 0;
  deliverycount: number = 0;
  receivedcount: number = 0;
  allcount: number = 0;

  fireshipment: any = [];


  private gridApi;
  private gridColumnApi;

  columnDefs = [
    { headerName: 'Order', field: 'orderno', sortable: true, filter: true },
    { headerName: 'Order Date', field: 'orderdate', sortable: true, filter: true, valueFormatter: this.fnMomentDateTimeFormatter },
    { headerName: 'Expect Date', field: 'expectdeliverydt', sortable: true, filter: true, valueFormatter: this.fnMomentDateTimeFormatter },
    { headerName: 'Warehouse', field: 'warehousename', sortable: true, filter: true },
    { headerName: 'Customer', field: 'customername', sortable: true, filter: true },
    { headerName: 'Mobile', field: 'mobile', sortable: true, filter: true },
    { headerName: 'Shipment No', field: 'deliveryorderno', sortable: true, filter: true },
    { headerName: 'Shipment Date', field: 'deliveryorderdate', sortable: true, filter: true, valueFormatter: this.fnMomentDateFormatter },
    {
      headerName: 'Order Status', field: 'orderno', sortable: true, filter: true,
      cellRenderer: (params) => {
        var deptBO = params.data;
        if (!deptBO.isconfirm && !deptBO.isreadytopick && !deptBO.isassigned && !deptBO.isdelivered && !deptBO.iscancelled) {
          return 'Order Placed'
        }
        else if (deptBO.isconfirm && !deptBO.isreadytopick && !deptBO.isassigned && !deptBO.isdelivered && !deptBO.iscancelled) {
          return 'To Pack'
        }
        else if (deptBO.isreadytopick && !deptBO.isassigned && !deptBO.ispicked && !deptBO.isdelivered && !deptBO.iscancelled) {
          return 'Ready To Pick'
        }
        else if (deptBO.isassigned && !deptBO.ispicked && !deptBO.isdelivered) {
          return 'Assigned'
        }
        else if (deptBO.ispicked && !deptBO.isdelivered) {
          return 'Picked'
        }
        else if (deptBO.isdelivered && !deptBO.isreceived) {
          return 'Delivered'
        }
        else if (deptBO.isdelivered && deptBO.isreceived) {
          return 'Received'
        }
        else if (deptBO.iscancelled) {
          return 'Cancelled'
        }
        else {
          return '';
        }
      },
      cellStyle: function (params) {
        var deptBO = params.data;
        if (!deptBO.isconfirm && !deptBO.isreadytopick && !deptBO.isassigned && !deptBO.isdelivered && !deptBO.iscancelled) {
          return { color: '#f39c12' }
        }
        else if (deptBO.isconfirm && !deptBO.isreadytopick && !deptBO.isassigned && !deptBO.isdelivered && !deptBO.iscancelled) {
          return { color: 'gray' }
        }
        else if (deptBO.isreadytopick && !deptBO.isassigned && !deptBO.ispicked && !deptBO.isdelivered && !deptBO.iscancelled) {
          return { color: 'darkblue' }
        }
        else if (deptBO.isassigned && !deptBO.ispicked && !deptBO.isdelivered && !deptBO.iscancelled) {
          return { color: 'gray' }
        }
        else if (deptBO.ispicked && !deptBO.isdelivered && !deptBO.iscancelled) {
          return { color: 'sandybrown' }
        }
        else if (deptBO.isdelivered && !deptBO.isreceived && !deptBO.iscancelled) {
          return { color: '#00c0ef' }
        }
        else if (deptBO.isdelivered && deptBO.isreceived && !deptBO.iscancelled) {
          return { color: 'green' }
        }
        else if (deptBO.iscancelled) {
          return { color: 'red' }
        }
        else {
          return '';
        }
      },
    },
    { headerName: 'Items', field: 'productcount', sortable: true, filter: true },
    { headerName: 'Total', field: 'grandtotal', sortable: true, filter: true, valueFormatter: params => params.data.grandtotal.toFixed(2) },
    { headerName: 'City', field: 'cityname', sortable: true, filter: true },
    { headerName: 'Area', field: 'areaname', sortable: true, filter: true },
    { headerName: 'Branch', field: 'branch', sortable: true, filter: true },
  
    { headerName: 'Delivery Type', field: 'deliverytype', sortable: true, filter: true },
    { headerName: 'Paymode', field: 'paymode', sortable: true, filter: true },
    { headerName: 'Confirmed', field: 'confirmbyname', sortable: true, filter: true },
    { headerName: 'ConfirmOn', field: 'confirmon', sortable: true, filter: true, valueFormatter: this.fnMomentDateTimeFormatter },
    { headerName: 'ReadyToPick', field: 'readytopickbyname', sortable: true, filter: true },
    { headerName: 'ReadyToPickOn', field: 'readytopickon', sortable: true, filter: true, valueFormatter: this.fnMomentDateTimeFormatter },
    { headerName: 'Assigned', field: 'assignedbyname', sortable: true, filter: true },
    { headerName: 'AssignedOn', field: 'assignedon', sortable: true, filter: true, valueFormatter: this.fnMomentDateTimeFormatter },
    { headerName: 'Picked', field: 'pickedbyname', sortable: true, filter: true },
    { headerName: 'PickedOn', field: 'pickedon', sortable: true, filter: true, valueFormatter: this.fnMomentDateTimeFormatter },
    { headerName: 'Delivered', field: 'deliveredbyname', sortable: true, filter: true },
    { headerName: 'DeliveredOn', field: 'deliveredon', sortable: true, filter: true, valueFormatter: this.fnMomentDateTimeFormatter },
    { headerName: 'Received', field: 'receivedbyname', sortable: true, filter: true },
    { headerName: 'ReceivedOn', field: 'receivedon', sortable: true, filter: true, valueFormatter: this.fnMomentDateTimeFormatter },
  ]

  gridOptions = {
    rowSelection: 'single',
    rowHeight: 40,
    columnDefs: this.columnDefs,
    defaultColDef: {
      resizable: true,
      flex: 1,
      width: 100,
      minWidth: 100,
      enableValue: false,
      enableRowGroup: true,
      enablePivot: true,
      sortable: true,
      filter: true,
    },
  };

  _routerSub = Subscription.EMPTY;
  _routerLoginSub = Subscription.EMPTY

  constructor(private appService: DhukanDataService, public router: Router, private route: ActivatedRoute,
    private confirmationDialogService: ConfirmationDialogService, private toastr: ToastrService, private loginService: LoginDataService,
    private productFindService: NgbModal, private firebasedataservice: FibaseDataService, private firestore: AngularFireDatabase, private datePipe: DatePipe) {
  this._routerSub = this.route.queryParams.subscribe(params => {
    if (params.deliveryorderid !== undefined) {
       
      this.deliveryorderid = params.deliveryorderid
 
    }
    if (params.orderid !== undefined) {
       
      this.orderid = params.orderid
 
    }
      if (params.filterby !== undefined) {
       
        this.filterby = params.filterby
   
      }
      if (params.warehouseid !== undefined) {
        this.warehouseid = Number(params.warehouseid)
      }
     
      if (params.statusid !== undefined) {
        this.statusid = Number(params.statusid)
      }
    });


    this.fromdate = new Date();
    this.todate = new Date();

    this.fnServiceChanges();
  }

  //User Check
  fnServiceChanges() {
    this._routerLoginSub = this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isorderassign || this.isorderreadytopick || this.isorderpick || this.isorderdeliver) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.fnUserFailedForm();
      }
    });


    this.firebasedataservice.fnGetShipmentList().subscribe(res => {

      this.fireshipment = res;
      this.fireshipment.forEach(element => {
        if (element.id === "ADMIN") {
          if (element.ischange === 1) {
            this.fngetOrderListView();
            this.fnFirebaseSetAdminChanges();
          }
        }
      })
    })

  }

  ngOnInit() {
    try {



      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isorderassign || this.isorderreadytopick || this.isorderpick || this.isorderdeliver) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {

 
    if (this.filterby === "") {


      if (this.isorderreadytopick) {
        this.filterby = "PENDINGSHIPMENT";
      }
      else if (this.isorderassign) {
        this.filterby = "READYTOPICK";
      }
      else if (this.isorderpick) {
        this.filterby = "ASSIGNED";
      }
      else if (this.isorderdeliver) {
        this.filterby = "PICKED";
      }

    }

    this.fngetStatus();

    this.fngetWarehouse()
    
    this.fngetOrderListView();
  }

  fnCheckPrivilage() {
    this.isadd = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE');
    this.isedit = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER');
    this.isdelete = this.loginService.fnCheckUserRoleAction(this.formname, 'DELETE');
    this.isview = this.loginService.fnCheckUserRoleAction(this.formname, 'VIEW');
    this.isprint = this.loginService.fnCheckUserRoleAction(this.formname, 'PRINT');
    this.isexport = this.loginService.fnCheckUserRoleAction(this.formname, 'EXPORT');

    this.isorderassign = this.loginService.fnCheckUserRoleAction("ShipmentAssign", 'ALTER');
    this.isorderreadytopick = this.loginService.fnCheckUserRoleAction("ShipmentReadyToPick", 'ALTER');
    this.isorderpick = this.loginService.fnCheckUserRoleAction("ShipmentPick", 'ALTER');
    this.isorderdeliver = this.loginService.fnCheckUserRoleAction("ShipmentDeliver", 'ALTER');
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }


  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {

    if (this._routerSub !== undefined) {
      this._routerSub.unsubscribe();
    }

    if (this._routerLoginSub !== undefined) {
      this._routerLoginSub.unsubscribe();
    }
  }


  //Grid
  fnGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    this.fnSetDataTable()
  }


  //Event  
  fnActionEditClick() {
    if (!this.gridApi) {
      return;
    }

    if (this.gridApi.getSelectedRows().length > 0) {
      var data = this.gridApi.getSelectedRows()[0];
      this.fnEditDeliveryOrder(data.deliveryorderid, data.orderid);
    }
  }

  fnActionExportClick() {
    const params = {
      columnGroups: true,
      allColumns: true,
      fileName: 'orderlist',
    };
    this.loginService.fnExportdataCsv(params, this.gridApi)
    // this.gridApi.exportDataAsCsv(params);
  }

  fngetWarehouse() {

    var data = {
      'warehouseid': 0,

    }
    this.appService.getWarehouse(data)
      .subscribe(
        (res) => {

          if (res.json().status == 200) {
            this.warehouseColl = res.json().result;
            console.log(this.warehouseColl)
            // if (this.warehouseColl.length > 0 && !this.warehouseid) {
            //   this.warehouseid = this.warehouseColl[0].warehouseid;
            // //  this.fngetReportListView()
            // }

            // this.fngetOrderListView();
          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }

        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }
  fngetStatus() {

    var data = {
      'warehouseid': this.warehouseid,
      'modifiedby': this.loginService.fnGetModUserId(),
    }
    this.appService.fnGetDeliveryOrderStatus(data)
      .subscribe(
        (res) => {

          if (res.json().status == 200) {
            let result = res.json().result[0];

            this.unassigncount = result.unassigncount;
            this.assigncount = result.assigncount;
            this.readytopickcount = result.readytopickcount;
            this.pickedcount = result.pickedcount;
            this.deliverycount = result.deliveredcount;
            this.receivedcount = result.receivedcount;
            this.allcount = result.allcount;

            

          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }


        },
        (err) => {

          this.toastr.error('Error', err);
        })
  }

  fngetOrderListView() {

    if (this.showProgress) {
      return;
    }

    if (this.showProgress) {
      return;
    }

    this.isselectlist = false;
    this.fnShowProgress();
    var data = {
      'statusid': this.statusid,
      'warehouseid': this.warehouseid,
      filterby: this.filterby,
      fromdate: this.fnFormatDatetime(this.fromdate),
      todate: this.fnFormatDatetime(this.todate),
      modifiedby: this.loginService.fnGetModUserId(),
    }
    this.appService.getDeliveryOrderListView(data)
      .subscribe(
        (res) => {
          this.fnHideProgress();
          if (res.json().status == 200) {
            this.deliveryorderColl = res.json().result;
            this.fnSetDataTable();

            this.fngetStatus();
            this.fnCurrentRouteChange();
          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }
        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }


  fnAddDeliveryOrder() {
    try {


      this.router.navigate(['/AddDeliveryOrders']);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }
  fnCurrentRouteChange() {

    const queryParams: Params = {
      'filterby': this.filterby,
      'warehouseid': this.warehouseid,
      'statusid': this.statusid,
      'oid': this.orderid,
      'deliveryorderid': this.deliveryorderid,
    };
   
      
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: queryParams,

      });
  }


  fnEditDeliveryOrder(deptid, orderid) {
    try {
      let navigationExtras: NavigationExtras = {
        queryParams: {
          'orderid': orderid,
          'id': deptid,
          'ltype': 'list',
          'warehouseid': this.warehouseid,
          'filterby': this.filterby
        }
      }

      this.router.navigate(['/AddShipment'], navigationExtras);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  public fnDeleteConfirm(deptid) {
    try {
      this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete Delivery Order?')
        .then((confirmed) => {
          if (confirmed) {
            this.fnDeleteDeliveryOrder(deptid)
          }
        }
        );
      //.catch(() => );
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  private fnDeleteDeliveryOrder(deptid) {
    var data = {
      'deliveryorderid': deptid,
    }
    this.appService.fnDeleteDeliveryOrder(data)
      .subscribe(
        (res) => {
          if (res.json().status === 200) {
            this.toastr.success('', " The Delivery Order has been deleted successfully.");
            this.fngetOrderListView();
          } else {
            this.toastr.error('', res.json().err_field);
          }
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  private fnSetDataTable() {
    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();

      var allColIds = this.gridColumnApi.getAllColumns()
        .map(column => column.colId);
      this.gridColumnApi.autoSizeColumns(allColIds);

      // If you want to resize all columns
      this.gridColumnApi.autoSizeAllColumns();


    }

  }



  fnCheckValue(values: any, index) {

    this.deliveryorderColl[index].ischeck = values.currentTarget.checked;

    this.isselectlist = false;
    const pindex = this.deliveryorderColl.findIndex(sku => sku.ischeck === true);

    if (pindex > -1) {

      this.isselectlist = true;
    }

  }



  //Delivery Order Status
  fnDeliveryOrderAssigned() {
    const modalRef = this.productFindService.open(AdddeliveryoptionComponent, { size: <any>'l' });
    modalRef.componentInstance.doctype = "";
    modalRef.componentInstance.displaytxt = "Assign Delivery Boy";

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

      let deliveryboyid = 0

      deliveryboyid = receivedEntry.deliveryboyid;

      this.fnShowProgress();

      for (let abo of this.deliveryorderColl) {
        if (abo.ischeck) {
          this.fnSetAssignDeliveryOrder(abo.deliveryorderid, deliveryboyid)
        }

      }

      this.fnHideProgress();

      this.fngetOrderListView();

    });

  }

  fnSetAssignDeliveryOrder(deliveryorderid, deliveryboyid) {

    var data = {
      deliveryorderid: deliveryorderid,
      isassigned: true,
      assignedby: deliveryboyid,
      modifiedby: this.loginService.fnGetModUserId(),
    }
    this.appService.fnSetDeliveryOrderAssigned(data)
      .subscribe(
        (res) => {

          if (res.json().status == 200) {

            var _res = res.json().result[0][0];

            this.appService.fnSendPushNotification(_res.title, _res.content, _res.orderid, _res.deliveryboyid, deliveryorderid);

            this.fnFirebaseSetDeliverBoyChanges(_res.deliveryboyid);

            this.fnInsertActivityLog('ASSIGNED', deliveryboyid, this.fnGetDescription(deliveryorderid), this.fnGetOrderid(deliveryorderid), deliveryorderid);

            this.fngetOrderListView();
          }
          else if (res.json().status == 400) {
            this.fnHideProgress();
            this.toastr.error('Error', res.json().err_field);
          }
        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }

  fnSetUnAssignDeliveryOrder(deliveryorderid) {

    var data = {
      deliveryorderid: deliveryorderid,
      isassigned: false,
      assignedby: 0,
      modifiedby: this.loginService.fnGetModUserId(),
    }
    this.appService.fnSetDeliveryOrderAssigned(data)
      .subscribe(
        (res) => {

          if (res.json().status == 200) {
            this.fnInsertActivityLog('UNASSIGNED', deliveryorderid, this.fnGetDescription(deliveryorderid), this.fnGetOrderid(deliveryorderid), deliveryorderid);
            this.fngetOrderListView();
          }
          else if (res.json().status == 400) {
            this.fnHideProgress();
            this.toastr.error('Error', res.json().err_field);
          }
        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }



  fnDeliveryOrderReadyToPick() {
    this.confirmationDialogService.confirm('Confirm..', 'Do you  want to ready to pick Shipment?')
      .then((confirmed) => {
        if (confirmed) {
          this.fnShowProgress();

          for (let abo of this.deliveryorderColl) {
            if (abo.ischeck) {
              this.fnSetReadyToPickDeliveryOrder(abo.deliveryorderid)
            }

          }

          this.fnHideProgress();
        }
      }
      );

  }

  fnSetReadyToPickDeliveryOrder(deliveryorderid) {


    var data = {
      deliveryorderid: deliveryorderid,
      isreadytopick: true,
      modifiedby: this.loginService.fnGetModUserId(),
      fromdate: this.fnFormatDatetime(this.fromdate),
      todate: this.fnFormatDatetime(this.todate),
    }
    this.appService.fnSetDeliveryOrderReadyToPick(data)
      .subscribe(
        (res) => {

          if (res.json().status == 200) {
            this.fnInsertActivityLog('READYTOPICK', deliveryorderid, this.fnGetDescription(deliveryorderid), this.fnGetOrderid(deliveryorderid), deliveryorderid);

            this.fngetOrderListView();
          }
          else if (res.json().status == 400) {
            this.fnHideProgress();
            this.toastr.error('Error', res.json().err_field);
          }
        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }

  fnSetUnReadyToPickDeliveryOrder(deliveryorderid) {


    var data = {
      deliveryorderid: deliveryorderid,
      isreadytopick: false,
      modifiedby: this.loginService.fnGetModUserId(),
    }
    this.appService.fnSetDeliveryOrderReadyToPick(data)
      .subscribe(
        (res) => {

          if (res.json().status == 200) {
            this.fnInsertActivityLog('UNREADYTOPICK', deliveryorderid, this.fnGetDescription(deliveryorderid), this.fnGetOrderid(deliveryorderid), deliveryorderid);

            this.fngetOrderListView();
          }
          else if (res.json().status == 400) {
            this.fnHideProgress();
            this.toastr.error('Error', res.json().err_field);
          }
        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }


  fnDeliveryOrderPicked() {
    const modalRef = this.productFindService.open(AdddeliveryoptionComponent, { size: <any>'l' });
    modalRef.componentInstance.doctype = "";
    modalRef.componentInstance.displaytxt = "Picked Delivery Boy";

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

      let deliveryboyid = 0

      deliveryboyid = receivedEntry.deliveryboyid;

      this.fnShowProgress();

      for (let abo of this.deliveryorderColl) {
        if (abo.ischeck) {
          this.fnSetPickDeliveryOrder(abo.deliveryorderid, deliveryboyid)
        }

      }

      this.fnHideProgress();

      //this.fngetOrderListView();

    });

  }

  fnSetPickDeliveryOrder(deliveryorderid, deliveryboyid) {


    var data = {
      deliveryorderid: deliveryorderid,
      ispicked: true,
      pickedby: deliveryboyid,
      modifiedby: this.loginService.fnGetModUserId(),
    }
    this.appService.fnSetDeliveryOrderPicked(data)
      .subscribe(
        (res) => {

          if (res.json().status == 200) {
            this.fnInsertActivityLog('PICKED', deliveryboyid, this.fnGetDescription(deliveryorderid), this.fnGetOrderid(deliveryorderid), deliveryorderid);
            this.fngetOrderListView();
          }
          else if (res.json().status == 400) {
            this.fnHideProgress();
            this.toastr.error('Error', res.json().err_field);
          }
        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }

  fnSetUnPickDeliveryOrder(deliveryorderid) {


    var data = {
      deliveryorderid: deliveryorderid,
      ispicked: false,
      pickedby: 0,
      modifiedby: this.loginService.fnGetModUserId(),
    }
    this.appService.fnSetDeliveryOrderPicked(data)
      .subscribe(
        (res) => {

          if (res.json().status == 200) {
            this.fnInsertActivityLog('UNPICKED', deliveryorderid, this.fnGetDescription(deliveryorderid), this.fnGetOrderid(deliveryorderid), deliveryorderid);
            this.fngetOrderListView();
          }
          else if (res.json().status == 400) {
            this.fnHideProgress();
            this.toastr.error('Error', res.json().err_field);
          }
        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }

  fnDeliveryOrderDelivered() {
    const modalRef = this.productFindService.open(AdddeliveryoptionComponent, { size: <any>'l' });
    modalRef.componentInstance.doctype = "";
    modalRef.componentInstance.displaytxt = "Delivered Delivery Boy";

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

      let deliveryboyid = 0

      deliveryboyid = receivedEntry.deliveryboyid;

      this.fnShowProgress();

      for (let abo of this.deliveryorderColl) {
        if (abo.ischeck) {
          this.fnSetDeliveredDeliveryOrder(abo.deliveryorderid, deliveryboyid)
        }

      }

      this.fnHideProgress();



    });

  }

  fnSetDeliveredDeliveryOrder(deliveryorderid, deliveryboyid) {


    var data = {
      deliveryorderid: deliveryorderid,
      isdelivered: true,
      deliveredby: deliveryboyid,
      modifiedby: this.loginService.fnGetModUserId(),
    }
    this.appService.fnSetDeliveryOrderDelivered(data)
      .subscribe(
        (res) => {

          if (res.json().status == 200) {
            this.fnInsertActivityLog('DELIVERED', deliveryboyid, this.fnGetDescription(deliveryorderid), this.fnGetOrderid(deliveryorderid), deliveryorderid);
            this.fngetOrderListView();
          }
          else if (res.json().status == 400) {
            this.fnHideProgress();
            this.toastr.error('Error', res.json().err_field);
          }
        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }

  fnSetUnDeliveredDeliveryOrder(deliveryorderid) {


    var data = {
      deliveryorderid: deliveryorderid,
      isdelivered: false,
      deliveredby: 0,
      modifiedby: this.loginService.fnGetModUserId(),
    }
    this.appService.fnSetDeliveryOrderDelivered(data)
      .subscribe(
        (res) => {

          if (res.json().status == 200) {
            this.fnInsertActivityLog('UNDELIVERED', deliveryorderid, this.fnGetDescription(deliveryorderid), this.fnGetOrderid(deliveryorderid), deliveryorderid);
            this.fngetOrderListView();
          }
          else if (res.json().status == 400) {
            this.fnHideProgress();
            this.toastr.error('Error', res.json().err_field);
          }
        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }

  fnDeliveryOrderReceived() {
    this.confirmationDialogService.confirm('Confirm..', 'Do you  want to Received Shipment?')
      .then((confirmed) => {
        if (confirmed) {
          this.fnShowProgress();

          for (let abo of this.deliveryorderColl) {
            if (abo.ischeck) {
              this.fnSetReceivedDeliveryOrder(abo.deliveryorderid)
            }

          }

          this.fnHideProgress();
        }
      }
      );

  }

  fnSetReceivedDeliveryOrder(deliveryorderid) {


    var data = {
      deliveryorderid: deliveryorderid,
      isreceived: true,
      modifiedby: this.loginService.fnGetModUserId(),
    }
    this.appService.fnSetDeliveryOrderReceived(data)
      .subscribe(
        (res) => {

          if (res.json().status == 200) {
            this.fngetOrderListView();
          }
          else if (res.json().status == 400) {
            this.fnHideProgress();
            this.toastr.error('Error', res.json().err_field);
          }
        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }

  fnSetUnReceivedDeliveryOrder(deliveryorderid) {


    var data = {
      deliveryorderid: deliveryorderid,
      isreceived: false,
      modifiedby: this.loginService.fnGetModUserId(),
    }
    this.appService.fnSetDeliveryOrderReceived(data)
      .subscribe(
        (res) => {

          if (res.json().status == 200) {
            this.fngetOrderListView();
          }
          else if (res.json().status == 400) {
            this.fnHideProgress();
            this.toastr.error('Error', res.json().err_field);
          }
        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }


  //RevertStatus
  fnConfirmRevertStatus(type) {
    this.confirmationDialogService.confirm('Confirm..', 'Do you  want to ' + type + ' Shipment?')
      .then((confirmed) => {
        if (confirmed) {
          this.fnShowProgress();


          for (let abo of this.deliveryorderColl) {
            if (abo.ischeck) {
              if (type === 'UnAssign') {
                this.fnSetUnAssignDeliveryOrder(abo.deliveryorderid)
              }
              else if (type === 'UnReadyToPick') {
                this.fnSetUnReadyToPickDeliveryOrder(abo.deliveryorderid)
              }
              else if (type === 'UnPick') {
                this.fnSetUnPickDeliveryOrder(abo.deliveryorderid)
              }
              else if (type === 'UnDelivery') {
                this.fnSetUnDeliveredDeliveryOrder(abo.deliveryorderid)
              }
              else if (type === 'UnReceive') {
                this.fnSetUnReceivedDeliveryOrder(abo.deliveryorderid)
              }
            }

          }

          this.fnHideProgress();
        }
      }
      );

  }


  //Firebase

  fnFirebaseSetAdminChanges() {
    // this.fireshipment.forEach(element => {             
    //   if(element.id==="ADMIN"){           
    //     if(element.ischange===1){
    //       this.fngetOrderListView();
    //     }
    //   }                   
    // })


    this.firestore.database.ref('ShipmentChanges/').child("ADMIN").set({ id: "ADMIN", ischange: 0 })
  }

  fnFirebaseSetDeliverBoyChanges(userid) {

    let _ischeck: boolean = false;
    let _idenid = ""


    this.fireshipment.forEach(element => {
      if (element.id === userid) {
        _idenid = element.idenid;
        _ischeck = true;
      }
    })

    if (_ischeck) {
      this.firestore.database.ref('ShipmentChanges/').child(_idenid).set({
        idenid: _idenid,
        id: userid,
        ischange: 1
      })
    }
    else {
      let bloglist: AngularFireList<any>;
      bloglist = this.firestore.list('/ShipmentChanges');
      var _odid = bloglist.query.ref.push();


      this.firestore.database.ref('ShipmentChanges/').child(_odid.key).set({
        idenid: _odid.key,
        id: userid,
        ischange: 1
      })

    }





  }


  //Map
  fnGeoLocationDeliveryBoy() {


    const modalRef = this.productFindService.open(DeliveryboyaddressmapComponent, { size: <any>'xl' });
    modalRef.componentInstance.doctype = "COMBO PRODUCT";
    modalRef.componentInstance.deliveryboymaptype = "ALL";
    modalRef.componentInstance.fngetDeliveryBoy();
    modalRef.componentInstance.fnServiceChanges();
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {


    });


  }



  fnPrintData() {
    try {


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnExportData() {
    try {

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }


  fnGetOrderid(id) {
    const index = this.deliveryorderColl.findIndex(sku => sku.deliveryorderid === id);

    if (index) {
      var _desc = 0;
      if (this.deliveryorderColl[index].orderid !== undefined) {
        _desc = this.deliveryorderColl[index].orderid
      }

      return _desc;
    }
    else {
      return 0;
    }

  }


  fnGetDescription(id) {
    const index = this.deliveryorderColl.findIndex(sku => sku.deliveryorderid === id);

    if (index) {
      var _desc = "";

      if (this.deliveryorderColl[index].orderno !== undefined) {
        _desc = this.deliveryorderColl[index].orderno
      }

      if (this.deliveryorderColl[index].deliveryorderno !== undefined) {
        _desc = _desc + "-" + this.deliveryorderColl[index].deliveryorderno
      }
      return _desc;
    }
    else {
      return "";
    }

  }

  fnInsertActivityLog(action, refno, desc, orderid, deliveryorderid) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
        orderid: orderid,
        deliveryorderid: deliveryorderid
      }


      this.appService.fnInsertActivity(data).subscribe(
        (res) => {
        },
        (err) => {

        });

    } catch {

    }
  }


  fnFormatDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        return this.datePipe.transform(dt, "yyyy-MM-dd")
      }

    } else {
      return null
    }

  }

  fnConvertDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {


        var dateParts = dt.split("-");

        var sdate = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2] + 1);

        sdate.setMinutes(sdate.getMinutes() + sdate.getTimezoneOffset());



        return sdate;
      }

    } else {
      return null
    }

  }


  fnMomentDateFormatter(params) {
    if (params.value) {
      return moment(params.value).utcOffset('+02:30', false).format('DD-MM-YYYY');//hh:mm a  
    }
    else {
      return '';
    }
  }

  fnMomentDateTimeFormatter(params) {
    if (params.value) {
      return moment(params.value).utcOffset('+02:30', false).format('DD-MM-YYYY hh:mm a');//  
    }
    else {
      return '';
    }

  }

  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }


  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }




}


import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ImageformatterComponent } from '../grid/imageformatter/imageformatter.component';

@Component({
  selector: 'app-productfind',
  templateUrl: './productfind.component.html',
  styleUrls: ['./productfind.component.css']
})
export class ProductfindComponent implements OnInit,AfterViewInit, OnDestroy {
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();

  
  @Input() doctype: string;
  @Input() req:{};
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() msgboxtype: string;
  @Output() emitData = new EventEmitter();
  @Input() offerwarehouseid: any = null;

  
  constructor(private activeModal: NgbActiveModal,private appService: DhukanDataService,private toastr: ToastrService) { }

  productColl:any=[];
  deptColl:any=[];
  categoryColl:any=[];
  subcategoryColl:any=[];
  brandColl:any=[];
  warehouseColl :any=[];
  productgroupColl :any=[];

  deptid:number=null;
  categoryid:number=null;  
  subcategoryid:number=null;  
  brandid:number=null;
  warehouseid:number=null;
  sku:string="";
  productname:string="";
  productgroupid:number=null;

  dtOptions: DataTables.Settings = {};
  filterproductColl:any=[];
  showProgress:boolean=false;

  
  private gridApi;
  private gridColumnApi;

  columnDefs = [
    {
      headerName: 'Operator',
      checkboxSelection: false,
      headerCheckboxSelection: false,
      filter: false,
      sortable: false,
      pinned: 'left',
      field: 'ischeck',
      cellRenderer: function (params) {
        let operatorValue = params.value;
        const input = document.createElement('input');
        input.type = 'checkbox';
        if (operatorValue) {
          input.checked = true;
          params.data.ischeck = true;
        } else {
          input.checked = false;
          params.data.ischeck = false;
        }
        input.addEventListener('click', function (event) {        
          input.checked != input.checked;
          params.data.ischeck = input.checked;
        });
        return input;
      }
    },      
    { headerName: 'Pic', field: 'picurl', width: 100,sortable: false, cellRendererFramework: ImageformatterComponent,pinned: 'left' },
    { headerName: 'Sku', field: 'sku', width:110,minWidth: 110,sortable: true, filter: true,pinned: 'left'},//,pinned: 'left'    
    { headerName: 'Name', field: 'productname', width:260,minWidth: 260, sortable: true, filter: true,pinned: 'left' },     
    { headerName: 'Department', field: 'deptname', width:120,minWidth: 120, sortable: true, filter: true },     
    { headerName: 'Category', field: 'categoryname', width:120,minWidth: 120, sortable: true, filter: true },     
    { headerName: 'SubCategory', field: 'subcategoryname', width:150,minWidth: 150, sortable: true, filter: true },     
    { headerName: 'Brand', field: 'brandname', width:150,minWidth: 150, sortable: true, filter: true },     
    { headerName: 'MRP', field: 'mrp', sortable: true, filter: true },     
    { headerName: 'Price', field: 'sellingprice', sortable: true, filter: true },     
    { headerName: 'Stock', field: 'stock', sortable: true, filter: true }, 
    { headerName: 'Published', field: 'published', sortable: true, filter: true },  
    { headerName: 'Status', field: 'activestatus', sortable: true, filter: true },         
  ]

  gridOptions = {       
    rowSelection:'multiple',
   rowHeight: 40,   
    columnDefs: this.columnDefs,
    defaultColDef: {
      resizable: true,
      flex: 2,
      width:100,
      minWidth: 100,      
      enableValue: false,      
      enableRowGroup: true,      
      enablePivot: true,
      sortable: true,
      filter: true,       
    },  
    sideBar: 'columns',  
   
  };

  
  ngOnInit() {
    try{
      this.fngetWarehouse();
      this.fngetDepartment();
      this.fngetBrand();
      this.fngetProductGroup();
      
      this.dtOptions = {
        searching: true,       
        pageLength: 25,
        serverSide: false,
        processing: true,
        dom:'<"top"f>rt<"bottom"ilp><"clear">',   
        
        order:[1, 'desc'],
        scrollX: true,                 
        scrollCollapse: true,
                             
      };
      


      
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  
  fnGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;   

    if (this.doctype === 'SELECT PRODUCT') {
      this.gridColumnApi.setColumnVisible('ischeck', false);
      this.gridColumnApi.setRowSelection('single')
    }
    
  }

  
  public fnAgRowClicked(e) { 

    if (this.doctype === 'SELECT PRODUCT' && e.data) { 
      this.filterproductColl.push(e.data);
      this.emitData.emit(this.filterproductColl);  
      this.activeModal.close(true);  
    }
       
  }

  onSelectionChanged(event) {
// 
    
  }


  ngAfterViewInit(): void {
  //  this.dtTrigger.next();
  }

  ngOnDestroy(): void {
   // this.dtTrigger.unsubscribe();
  }

  fngetDepartment() {        
    var data = {
      'deptid':0,            
    }
    this.appService.getDepartment(data)
    .subscribe(
      (res)=>{                  
        this.deptColl=res.json().result;  

        if(this.deptColl.length>0){
       //   this.deptid=this.deptColl[0].deptid;
        }

        //this.fngetCategory();
       // this.fngetProduct();   
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
  }

  fngetCategory() {        
   
    var data = {
      'categoryid':0,        
      'deptid':this.deptid,     
    }
  
    if(this.deptid>0){
      this.appService.getCategory(data)
      .subscribe(
        (res)=>{                  
          this.categoryColl=res.json().result;    
          
          // if(this.categoryColl.length===1){
          //      this.categoryid=this.categoryColl[0].categoryid;
          //      this.fngetSubCategory();
          // }
        },
      (err)=>{          
        this.toastr.error('Error', err);
      })
    }
    else{
      this.categoryColl=[];
      this.categoryid=null;
      this.subcategoryColl=[];
      this.subcategoryid=null;
    }
  }


  fngetSubCategory() {        
   
    var data = {
      'subcategoryid':0,        
      'categoryid':this.categoryid,     
    }
  
    if(this.categoryid>0){
      this.appService.getSubCategory(data)
      .subscribe(
        (res)=>{                  
          this.subcategoryColl=res.json().result;     
        },
      (err)=>{          
        this.toastr.error('Error', err);
      })
    }
    else{
      this.subcategoryColl=[];
      this.subcategoryid=null;
    }
  }



  fngetBrand() {        
    var data = {
      'brandid':0,            
    }
    this.appService.getBrand(data)
    .subscribe(
      (res)=>{                  
        this.brandColl=res.json().result;     
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
  }
 
  
  fngetProductGroup() {        
    var data = {
      'productgroupid':0,            
    }
    this.appService.getProductGroup(data)
    .subscribe(
      (res)=>{                  
        this.productgroupColl=res.json().result;     
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
  }

  fngetWarehouse() {        
    var data = {
      'warehouseid':0,            
    }
    this.appService.getWarehouse(data)
    .subscribe(
      (res)=>{                  
        this.warehouseColl=res.json().result;     
  
        if(this.warehouseColl.length>0){
          this.warehouseid=this.warehouseColl[0].warehouseid;
        }
        if(this.warehouseColl.length > 0 && this.offerwarehouseid !=  null && this.offerwarehouseid > 0 ){
          this.warehouseid = this.offerwarehouseid;
        }

      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
  }

  

  
  fngetProduct() { 
    
              
    if(this.fnCheckEmptyCall()===true){
      if(this.deptColl.length>0 ){
        this.deptid=this.deptColl[0].deptid;
      }

      if(this.deptid){
        this.fngetCategory();
      }
      return
    }
     


    this.fnShowProgress();
    var data = {
      'productid': 0,            
      'deptid': this.deptid,            
      'categoryid': this.categoryid,            
      'subcategoryid': this.subcategoryid,          
      'brandid':this.brandid,
      'warehouseid':this.warehouseid,
      'sku':this.sku,
      'productname':this.productname,
      'productgroupid':this.productgroupid    
}
    this.appService.getProductByListView(data)
        .subscribe(
          (res)=>{                  
            if(res.json().status==200){       
              
             
              this.productColl=res.json().result;
             
             

              for(let wbo of this.productColl){
                wbo.ischeck=false;
              }

              this.fnHideProgress();

              this.fnSetDataTable();
            }
            else if(res.json().status==400){                
            //  alert(res.json().err_field);    
              this.fnHideProgress();                    
            }
                      
        },
          (err)=>{          
            this.fnHideProgress();
          //  alert(err);          
      })
}


  fnCheckEmptyCall(){
    
    

    var _ischeck:boolean=false;

    if(this.sku!=='' || this.productname!=='' || this.productgroupid>0){
      this.deptid=null;
      this.categoryid=null;
      this.subcategoryid=null;
      this.brandid=null;

      this.categoryColl=[];
      this.subcategoryColl=[];

    }



    if(Number(this.deptid)===0){
    
      if(this.sku!=='' || this.productname!=='' || this.productgroupid>0){
        return false;
      }
      else{
        return true;      
      }      
    }        
    else{
      return false;
    }   

    return _ischeck;
    
  }


  public decline() {
    this.activeModal.close(false);
  }

  public accept() {

        
    for(let wbo of this.productColl){
      if(wbo.ischeck===true){
        this.filterproductColl.push(wbo);
      }      
    }
 
    this.emitData.emit(this.filterproductColl);  
    this.activeModal.close(true);  
  }

  public dismiss() {
    this.activeModal.dismiss();
  }

  checkValue(values:any,index){
    this.productColl[index].ischeck=values.currentTarget.checked;    
  }


  private fnSelectProduct(index){

      this.filterproductColl.push(this.productColl[index]);
      this.emitData.emit(this.filterproductColl);  
      this.activeModal.close(true);  
  
  }


  private fnSetDataTable(){
    
    // this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {      
    //   dtInstance.destroy();      
    //   this.dtTrigger.next();
  
    // });
    }

    
private fnShowProgress(){
  this.showProgress=true;
}
private fnHideProgress(){
  this.showProgress=false;
}


    
  private  fnShowErrorMsg(ex){
    this.toastr.warning('Internal Error', ex);       
  }

}

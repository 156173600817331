import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { DatePipe } from '@angular/common';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdddeliveryoptionComponent } from '../adddeliveryoption/adddeliveryoption.component';
import { FibaseDataService } from 'src/app/services/dhukan/fibase-data.service';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker/models';
import { PrintorderComponent } from '../../orders/printorder/printorder.component';
import { ZoomproductComponent } from '../../orders/zoomproduct/zoomproduct.component';
import { OrderoptionComponent } from '../../orders/orderoption/orderoption.component';
import { AdderpinfoComponent } from '../adderpinfo/adderpinfo.component';

@Component({
  selector: 'app-addshipment',
  templateUrl: './addshipment.component.html',
  styleUrls: ['./addshipment.component.css']
})
export class AddshipmentComponent implements OnInit {

  //Mode Values
  isaddmode: boolean = false;
  iseditmode: boolean = false;
  isdeletemode: boolean = false;
  isviewmode: boolean = false;

  //Save Validation
  submitted: boolean;
  successfully: boolean;


  dateCustomClasses: DatepickerDateCustomClasses[];

  paramfilterby: String = '';

  //Edit Get Values
  orderColl: any = [];
  orderdetailsColl: any = [];
  orderid: number = 0;
  type: string = '';

  deliveryboyColl: any = [];

  deliveryorderColl: any = [];
  deliveryorderBO: any = {};

  deliveryorderdetailscoll: any = [];
  deliveryorderid: number = 0

  shipmenttypeColl: any = [];

  branchColl: any = [];

  deliveryorderform = new FormGroup({
    deliveryorderno: new FormControl('', Validators.required),
    deliveryorderdate: new FormControl(''),
    shipmenttypeid: new FormControl(0),
    trackno: new FormControl(''),
    branchid: new FormControl(null, Validators.required),
    deliveryorderid: new FormControl(0),
  });


  shipmentreadyform = new FormGroup({
    invoiceno: new FormControl('', Validators.required),
    invoicedate: new FormControl(null, Validators.required),
    invoiceamt: new FormControl(null, Validators.required),
    controllno: new FormControl('', Validators.required),
    taxamt: new FormControl(null, Validators.required),
  })

  currentorderBO: any = {};

  isorderassign: boolean = false;
  isorderreadytopick: boolean = false;
  isorderpick: boolean = false;
  isorderdeliver: boolean = false;
  isorderreceived: boolean = false;

  iseditshipment: boolean = false;
  isdeleteshipment: boolean = false;

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Shipment";

  fireshipment: any = [];

  statusid: number = 0;
  warehouseid: number = null;

  constructor(private appService: DhukanDataService, private route: ActivatedRoute, private loginService: LoginDataService,
    public router: Router, private productFindService: NgbModal, private toastr: ToastrService,
    private confirmationDialogService: ConfirmationDialogService, private datePipe: DatePipe,
    private firebasedataservice: FibaseDataService, private firestore: AngularFireDatabase) {

    this.dateCustomClasses = [
      { date: new Date(), classes: ['bg-warning'] },
    ];

    this.route.queryParams.subscribe(params => {
      if (params.id !== undefined) {
        this.deliveryorderid = params.id
      }
      if (params.oid !== undefined) {
        this.orderid = params.oid
      }
      if (params.ltype !== undefined) {
        this.type = params.ltype
      }
      if (params.filterby !== undefined) {
        this.paramfilterby = params.filterby
        
      }

      if (params.warehouseid !== undefined) {

        this.warehouseid = Number(params.warehouseid)

      }
      if (params.statusid !== undefined) {

        this.statusid = Number(params.statusid)

      }
    });

    this.fnServiceChanges();
  }


  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {

        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }

      }
      else {
        this.fnUserFailedForm();
      }
    });
  }

  ngOnInit() {
    try {
      if (this.loginService.fnCheckUser()) {
        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    try {
      this.isaddmode = true;
      this.deliveryorderform.reset();
      this.fnInitializeStatusFormGroup();

      if (this.deliveryorderid > 0) {
        this.fngetDeliveryOrder();
        this.fnSetViewMode();
      }
      else {
        this.fngetOrderDetails();
        this.fnSetEditMode();
      }

      this.fngetBranch();
      this.fngetShipmentType();
      this.fngetDeliveryBoy();

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }



  fnCheckUserPririvilage() {
    var _check: boolean = false;
    if (this.deliveryorderid > 0) {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'VIEW')
    } else {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE')
    }

    this.iseditshipment = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER')
    this.isdeleteshipment = this.loginService.fnCheckUserRoleAction(this.formname, 'DELETE')

    this.isorderassign = this.loginService.fnCheckUserRoleAction("ShipmentAssign", 'ALTER');
    this.isorderreadytopick = this.loginService.fnCheckUserRoleAction("ShipmentReadyToPick", 'ALTER');
    this.isorderpick = this.loginService.fnCheckUserRoleAction("ShipmentPick", 'ALTER');
    this.isorderdeliver = this.loginService.fnCheckUserRoleAction("ShipmentDeliver", 'ALTER');
    this.isorderreceived = this.loginService.fnCheckUserRoleAction("Settlement", 'ALTER');

    return _check;
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }

  fnInitializeStatusFormGroup() {
    this.deliveryorderform.setValue({
      deliveryorderno: 'NEW',
      deliveryorderdate: new Date(),
      shipmenttypeid: null,
      trackno: '',
      branchid: null,
      deliveryorderid: 0,
    })


    this.shipmentreadyform.setValue({
      invoiceno: '',
      invoicedate: null,
      invoiceamt: null,
      controllno: '',
      taxamt: null
    })
  }


  fngetOrderDetails() {
    this.fnShowProgress();
    var data = {
      'orderid': this.orderid,
    }
    this.appService.getOrderbyDeliverOrder(data)
      .subscribe(
        (res) => {
          this.fnHideProgress();
          this.orderdetailsColl = res.json().result[1];

          this.deliveryorderdetailscoll = []
          for (let _abo of this.orderdetailsColl) {
            _abo.availableqty = _abo.qty - _abo.prevqty;
            this.deliveryorderdetailscoll.push(_abo)
          }


        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }


  //Get 
  fngetDeliveryOrder() {
    this.fnShowProgress();
    var data = {
      'deliveryorderid': this.deliveryorderid,
    }
    this.appService.getDeliveryOrderbyOrderid(data)
      .subscribe(
        (res) => {
          this.fnHideProgress();
          this.deliveryorderColl = res.json().result[0];
          this.orderdetailsColl = res.json().result[1];

          this.deliveryorderBO = this.deliveryorderColl[0];

          var editdata = {
            'deliveryorderid': this.deliveryorderColl[0].deliveryorderid,
            'deliveryorderno': this.deliveryorderColl[0].deliveryorderno,
            'deliveryorderdate': this.deliveryorderColl[0].deliveryorderdate,
            'shipmenttypeid': this.deliveryorderColl[0].shipmenttypeid,
            'trackno': this.deliveryorderColl[0].trackno,
            branchid: this.deliveryorderColl[0].branchid,
            ispicked: this.deliveryorderColl[0].ispicked,
            pickedby: this.deliveryorderColl[0].pickedby,
            isdelivered: this.deliveryorderColl[0].isdelivered,
            deliveredby: this.deliveryorderColl[0].deliveredby,
          }
          this.fnEditMode(editdata);



          this.deliveryorderdetailscoll = []
          for (let _abo of this.orderdetailsColl) {
            _abo.availableqty = _abo.qty - _abo.prevqty;


            if (_abo.ischeck === 1) {
              _abo.availableqty = _abo.availableqty + _abo.deliveryqty;
            }

            if (_abo.ischeck === 0 && _abo.deliveryqty > 0) {
              _abo.deliveryqty = 0;
            }

            this.deliveryorderdetailscoll.push(_abo)
          }


        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }

  fngetShipmentType() {
    var data = {
      'shipmentypeid': 0,
    }
    this.appService.getShipment(data)
      .subscribe(
        (res) => {
          this.shipmenttypeColl = res.json().result;
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  fngetBranch() {
    var data = {
      'branchid': 0,
    }
    this.appService.getBranch(data)
      .subscribe(
        (res) => {
          this.branchColl = res.json().result;
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }


  fngetDeliveryBoy() {
    var data = {
      'deliveryboyid': 0,
    }
    this.appService.getDeliveryBoy(data)
      .subscribe(
        (res) => {
          if (res.json().status == 200) {
            this.deliveryboyColl = res.json().result;
          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }


        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }



  //Save Btn Click
  fnSaveBtnClick() {
    try {
      this.submitted = true;
      this.successfully = false;
      //Validate
      if (this.deliveryorderform.valid) {
        let _check = "";
        _check = this.fnCheckDeliveryDetails();
        if (_check === "ZERO") {
          this.confirmationDialogService.confirm('ALERT', 'Enter the Delivery Qty');
          return;
        }
        else if (_check === "QTY") {
          this.confirmationDialogService.confirm('ALERT', 'Delivery Qty Greater than Availabel Qty');
          return;
        }


        //Check
        var data = {
          'deliveryorderid': this.deliveryorderid,
          'deliveryorderno': this.deliveryorderform.get('deliveryorderno').value,
          'deliveryorderdate': this.fnFormatDatetime(this.deliveryorderform.get('deliveryorderdate').value),
          'productcount': this.deliveryorderdetailscoll.length,
          'qtytotal': this.fnGetQtyCount(),
          'shipmenttypeid': this.deliveryorderform.get('shipmenttypeid').value,
          'trackno': this.deliveryorderform.get('trackno').value,
          'branchid': this.deliveryorderform.get('branchid').value,
          'orderid': this.orderid,
          'createdby': this.loginService.fnGetModUserId(),
          'modifiedby': this.loginService.fnGetModUserId(),
          'deliveryorderdetailscoll': this.fnGetDeliveryDetails()
        }
        if (this.deliveryorderform.get('deliveryorderid').value > 0) {
          //Update Department
          this.fnSetDeliveryOrder(data);
        }
        else {
          //Insert Department
          this.fnInsertDeliveryOrder(data);
        }
      }
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  //Insert
  fnInsertDeliveryOrder(data) {
    this.fnShowProgress();
    this.appService.fnInsertDeliveryOrder(data).subscribe(
      (res) => {
        this.fnHideProgress();
        if (res.json().result != "") {
          this.toastr.success('', "The new deliveryorder has been added successfully.Insert Successfully");

          this.fnInsertActivityLog('INSERT', this.deliveryorderBO.orderno, res.json().result);

          this.fnCloseDeliveryOrder();
        }
      },
      (err) => {
        this.fnHideProgress();
        this.toastr.error('Error', err);
      });

  }

  //Set
  fnSetDeliveryOrder(data) {
    this.fnShowProgress();
    this.appService.fnSetDeliveryOrder(data).subscribe(
      (res) => {
        this.fnHideProgress();
        if (res.json().result != "") {
          this.toastr.success('', "The deliveryorder has been updated successfully.");


          this.fnInsertActivityLog('EDIT', this.deliveryorderBO.orderno, data.deliveryorderno);

          this.fnCloseDeliveryOrder();
        }
      },
      (err) => {
        this.fnHideProgress();
        this.toastr.error('Error', err);
      });

  }

  //Close
  fnCloseDeliveryOrder() {

    try {
      let navigationExtras: NavigationExtras = {
        queryParams: {
          'deliveryorderid': this.deliveryorderid,
          //'oid': this.orderid,
          'warehouseid': this.warehouseid,
          'statusid': this.statusid,
          'filterby': this.paramfilterby,
        }
      }

      if (this.type === 'list') {
        
        this.router.navigate(['/DeliveryOrders'], navigationExtras);
      }
      else if (this.type === 'settlelist') {
      
        this.router.navigate(['/Settlement'], navigationExtras);
      }
      else {
        this.router.navigate(['/AddOrders'], navigationExtras);
      }




    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  private fnEditMode(deptBO) {

    deptBO.deliveryorderdate = this.fnConvertDatetime(this.fnFormatDatetime(deptBO.deliveryorderdate));

    this.deliveryorderform.setValue({
      deliveryorderno: deptBO.deliveryorderno,
      deliveryorderdate: deptBO.deliveryorderdate,
      deliveryorderid: deptBO.deliveryorderid,
      shipmenttypeid: deptBO.shipmenttypeid,
      branchid: deptBO.branchid,
      trackno: deptBO.trackno,
      // ispicked:deptBO.ispicked,
      // pickedby:deptBO.pickedby,
      // isdelivered:deptBO.isdelivered,
      // deliveredby:deptBO.deliveredby,
    });



    // this.isaddmode=false;
    // this.iseditmode=true;
    // this.isdeletemode=false;
    // this.isviewmode=false;


  }



  fnAddDeliveryOrderItems() {

    for (let _abo of this.orderdetailsColl) {
      if (_abo.deliveryqty > 0) {
        _abo.orderno = this.currentorderBO.orderno;

        _abo.orderqty = _abo.qty;

        const index = this.deliveryorderdetailscoll.findIndex(sku => sku.orderdetailsid === _abo.orderdetailsid);
        if (index > -1) {
          this.deliveryorderdetailscoll[index].orderqty = _abo.qty;
          this.deliveryorderdetailscoll[index].deliveryqty = _abo.deliveryqty;
        }
        else {
          this.deliveryorderdetailscoll.push(_abo);
        }
      }
    }

  }

  checkValue(values: any, index) {
    this.deliveryorderdetailscoll[index].ischeck = values.currentTarget.checked;
    this.deliveryorderdetailscoll[index].deliveryqty = this.deliveryorderdetailscoll[index].availableqty;
  }

  fnSelectAllProduct(values: any) {
    for (let abo of this.deliveryorderdetailscoll) {
      if (abo.availableqty === 0 && !abo.isentry) {

      } else {
        abo.ischeck = values.currentTarget.checked;
        abo.deliveryqty = abo.availableqty;
      }
    }
  }

  fnGetQtyCount() {
    let _aqty: Number = 0;

    for (let _abo of this.deliveryorderdetailscoll) {
      _aqty = _aqty + _abo.deliveryqty;
    }

    return _aqty;
  }


  fnGetDeliveryDetails() {
    let _a: any = [];
    for (let abo of this.deliveryorderdetailscoll) {
      if (abo.ischeck === true || abo.ischeck === 1) {
        _a.push(abo);
      }
    }
    return _a;

  }

  fnCheckDeliveryDetails() {
    let _ischeck: string = "";

    for (let abo of this.deliveryorderdetailscoll) {
      if (abo.ischeck === true || abo.ischeck === 1) {
        if (abo.deliveryqty === 0) {
          _ischeck = "ZERO";
        }
        else if (abo.deliveryqty > abo.availableqty) {
          _ischeck = "QTY";
        }

      }
    }
    return _ischeck;
  }

  fnSetEditMode() {
    this.isviewmode = false;
    this.iseditmode = true;
  }

  fnSetViewMode() {
    this.isviewmode = true;
    this.iseditmode = false;
  }



  fnAddERPInfo() {


    const modalRef = this.productFindService.open(AdderpinfoComponent, { size: <any>'l' });

    modalRef.componentInstance.orderBO = this.deliveryorderBO;
    modalRef.componentInstance.trxid = this.deliveryorderid;
    modalRef.componentInstance.trxtype = "DELIVERYORDER";
    modalRef.componentInstance.fnSetShipment();

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

      window.location.reload()

    });

  }

  public fnDeleteConfirm(deptid) {
    try {
      this.confirmationDialogService.confirm('Confirm..', 'Do you  want to Revert Shipment?')
        .then((confirmed) => {
          if (confirmed) {
            this.fnDeleteDeliveryOrder(deptid)
          }
        }
        );
      //.catch(() => );
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  private fnDeleteDeliveryOrder(deptid) {
    var data = {
      'deliveryorderid': deptid,
    }
    this.appService.fnDeleteDeliveryOrder(data)
      .subscribe(
        (res) => {
          if (res.json().status === 200) {
            this.toastr.success('', " The Delivery Order has been deleted successfully.");

            this.fnInsertActivityLog('DELETE', this.deliveryorderBO.orderno, this.deliveryorderBO.deliveryorderno);

            this.fnCloseDeliveryOrder();
          } else {
            this.toastr.error('', res.json().err_field);
          }
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }




  //Delivery Order Status

  fnDeliveryOrderReadyToPick() {
    this.submitted = true;
    this.successfully = false;


    if (this.shipmentreadyform.valid) {
      this.confirmationDialogService.confirm('Confirm..', 'Do you  want to Ship?')
        .then((confirmed) => {
          if (confirmed) {
            this.fnShowProgress();

            this.fnSetReadyToPickDeliveryOrder(this.deliveryorderid)

            this.fnHideProgress();
          }
        }
        );
    }

  }

  fnSetReadyToPickDeliveryOrder(deliveryorderid) {

    var data = {
      deliveryorderid: deliveryorderid,
      isreadytopick: true,
      invoiceno: this.shipmentreadyform.get('invoiceno').value,
      invoicedate: this.fnFormatDatetime(this.shipmentreadyform.get('invoicedate').value),
      invoiceamt: this.shipmentreadyform.get('invoiceamt').value,
      taxamt: this.shipmentreadyform.get('taxamt').value,
      controllno: this.shipmentreadyform.get('controllno').value,
      modifiedby: this.loginService.fnGetModUserId(),
    }
    this.appService.fnSetDeliveryOrderReadyToPick(data)
      .subscribe(
        (res) => {

          if (res.json().status == 200) {
            var _result = res.json().result[0][0].checkresult
            if (_result === 'DONE') {
              this.fnInsertActivityLogStatus('READYTOPICK', deliveryorderid, this.fnGetDescription(deliveryorderid), this.fnGetOrderid(deliveryorderid), deliveryorderid);
              //this.fnCloseDeliveryOrder();
              this.fnSendNotificationByOrder('PACKED');
            }
            else if (_result === 'INVOICENO') {
              this.shipmentreadyform.controls['invoiceno'].setErrors({ 'notfound': true })
            }
            else if (_result === 'EXISTREADYTOPICK') {
              this.toastr.warning('', "Already Readytopick this Shipment");
              this.fnCloseDeliveryOrder();
            }
            else if (_result === 'EXISTASSIGNED') {
              this.toastr.warning('', "Already Assigned this Shipment");
              this.fnCloseDeliveryOrder();
            }
          }
          else if (res.json().status == 400) {
            this.fnHideProgress();
            this.toastr.error('Error', res.json().err_field);
          }
        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }

  fnSetUnReadyToPickDeliveryOrder(deliveryorderid) {


    var data = {
      deliveryorderid: deliveryorderid,
      isreadytopick: false,
      modifiedby: this.loginService.fnGetModUserId(),
    }
    this.appService.fnSetDeliveryOrderReadyToPick(data)
      .subscribe(
        (res) => {

          if (res.json().status == 200) {
            var _result = res.json().result[0][0].checkresult
            if (_result === 'DONE') {
              this.fnInsertActivityLogStatus('UNREADYTOPICK', deliveryorderid, this.fnGetDescription(deliveryorderid), this.fnGetOrderid(deliveryorderid), deliveryorderid);

              this.fnCloseDeliveryOrder();
            }
            else if (_result === 'EXISTASSIGNED') {
              this.toastr.warning('', "Already Assigned this Shipment");
              this.fnCloseDeliveryOrder();
            }
          }
          else if (res.json().status == 400) {
            this.fnHideProgress();
            this.toastr.error('Error', res.json().err_field);
          }
        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }

  fnDeliveryOrderAssigned() {
    const modalRef = this.productFindService.open(AdddeliveryoptionComponent, { size: <any>'l' });
    modalRef.componentInstance.doctype = "";
    modalRef.componentInstance.displaytxt = "Assign Delivery Boy";

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

      let deliveryboyid = 0

      deliveryboyid = receivedEntry.deliveryboyid;

      this.fnShowProgress();

      this.fnSetAssignDeliveryOrder(this.deliveryorderid, deliveryboyid)


      this.fnHideProgress();

      this.fnCloseDeliveryOrder();

    });

  }

  fnSetAssignDeliveryOrder(deliveryorderid, deliveryboyid) {

    var data = {
      deliveryorderid: deliveryorderid,
      isassigned: true,
      assignedby: deliveryboyid,
      modifiedby: this.loginService.fnGetModUserId(),
    }
    this.appService.fnSetDeliveryOrderAssigned(data)
      .subscribe(
        (res) => {

          if (res.json().status == 200) {

            var _res = res.json().result[0][0];
            if (_res.checkresult === 'DONE') {
              this.appService.fnSendPushNotification(_res.title, _res.content, _res.orderid, _res.deliveryboyid, deliveryorderid);

              this.fnFirebaseSetDeliverBoyChanges(_res.deliveryboyid);

              this.fnInsertActivityLogStatus('ASSIGNED', deliveryboyid, this.fnGetDescription(deliveryorderid), this.fnGetOrderid(deliveryorderid), deliveryorderid);

              this.fnCloseDeliveryOrder();
            }
            else if (_res.checkresult === 'EXISTASSIGNED') {
              this.toastr.warning('', "Already Assigned this Shipment");
              this.fnCloseDeliveryOrder();
            }
          }
          else if (res.json().status == 400) {
            this.fnHideProgress();
            this.toastr.error('Error', res.json().err_field);
          }
        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }

  fnSetUnAssignDeliveryOrder(deliveryorderid) {

    var data = {
      deliveryorderid: deliveryorderid,
      isassigned: false,
      assignedby: 0,
      modifiedby: this.loginService.fnGetModUserId(),
    }
    this.appService.fnSetDeliveryOrderAssigned(data)
      .subscribe(
        (res) => {

          if (res.json().status == 200) {
            var _res = res.json().result[0][0];
            if (_res.checkresult === 'DONE') {
              this.fnInsertActivityLogStatus('UNASSIGNED', deliveryorderid, this.fnGetDescription(deliveryorderid), this.fnGetOrderid(deliveryorderid), deliveryorderid);
              this.fnCloseDeliveryOrder();
            }
            else if (_res.checkresult === 'EXISTPICKED') {
              this.toastr.warning('', "Already Pick this Shipment");
              this.fnCloseDeliveryOrder();
            }

          }
          else if (res.json().status == 400) {
            this.fnHideProgress();
            this.toastr.error('Error', res.json().err_field);
          }
        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }



  fnDeliveryOrderPicked() {
    const modalRef = this.productFindService.open(AdddeliveryoptionComponent, { size: <any>'l' });
    modalRef.componentInstance.doctype = "";
    modalRef.componentInstance.displaytxt = "Picked Delivery Boy";

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

      let deliveryboyid = 0

      deliveryboyid = receivedEntry.deliveryboyid;

      this.fnShowProgress();


      this.fnSetPickDeliveryOrder(this.deliveryorderid, deliveryboyid)


      this.fnHideProgress();

      //this.fngetOrderListView();

    });

  }

  fnSetPickDeliveryOrder(deliveryorderid, deliveryboyid) {


    var data = {
      deliveryorderid: deliveryorderid,
      ispicked: true,
      pickedby: deliveryboyid,
      modifiedby: this.loginService.fnGetModUserId(),
    }
    this.appService.fnSetDeliveryOrderPicked(data)
      .subscribe(
        (res) => {

          if (res.json().status == 200) {
            var _res = res.json().result[0][0];

            if (_res.checkresult === 'DONE') {
              this.fnInsertActivityLogStatus('PICKED', deliveryboyid, this.fnGetDescription(deliveryorderid), this.fnGetOrderid(deliveryorderid), deliveryorderid);
              this.fnSendNotificationByOrder('PICKED');
            }
            else if (_res.checkresult === 'EXISTPICKED') {
              this.toastr.warning('', "Already Picked this Shipment");
              this.fnCloseDeliveryOrder();
            }

          }
          else if (res.json().status == 400) {
            this.fnHideProgress();
            this.toastr.error('Error', res.json().err_field);
          }
        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }

  fnSetUnPickDeliveryOrder(deliveryorderid) {


    var data = {
      deliveryorderid: deliveryorderid,
      ispicked: false,
      pickedby: 0,
      modifiedby: this.loginService.fnGetModUserId(),
    }
    this.appService.fnSetDeliveryOrderPicked(data)
      .subscribe(
        (res) => {

          if (res.json().status == 200) {
            var _res = res.json().result[0][0];

            if (_res.checkresult === 'DONE') {
              this.fnInsertActivityLogStatus('UNPICKED', deliveryorderid, this.fnGetDescription(deliveryorderid), this.fnGetOrderid(deliveryorderid), deliveryorderid);
              this.fnCloseDeliveryOrder();
            }
            else if (_res.checkresult === 'EXISTDELIVERED') {
              this.toastr.warning('', "Already Deliver this Shipment");
              this.fnCloseDeliveryOrder();
            }

          }
          else if (res.json().status == 400) {
            this.fnHideProgress();
            this.toastr.error('Error', res.json().err_field);
          }
        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }



  fnDeliveryOrderDelivered() {
    const modalRef = this.productFindService.open(AdddeliveryoptionComponent, { size: <any>'l' });
    modalRef.componentInstance.doctype = "";
    modalRef.componentInstance.displaytxt = "Delivered Delivery Boy";

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

      let deliveryboyid = 0

      deliveryboyid = receivedEntry.deliveryboyid;

      this.fnShowProgress();

      this.fnSetDeliveredDeliveryOrder(this.deliveryorderid, deliveryboyid)

      this.fnHideProgress();



    });

  }

  fnSetDeliveredDeliveryOrder(deliveryorderid, deliveryboyid) {

    this.fnShowProgress();
    var data = {
      deliveryorderid: deliveryorderid,
      isdelivered: true,
      deliveredby: deliveryboyid,
      modifiedby: this.loginService.fnGetModUserId(),
    }
    this.appService.fnSetDeliveryOrderDelivered(data)
      .subscribe(
        (res) => {
          this.fnHideProgress();
          if (res.json().status == 200) {
            var _res = res.json().result[0][0];
            if (_res.checkresult === 'DONE') {
              this.fnInsertActivityLogStatus('DELIVERED', deliveryboyid, this.fnGetDescription(deliveryorderid), this.fnGetOrderid(deliveryorderid), deliveryorderid);
              //this.fnCloseDeliveryOrder();
              this.fnSendMailByOrder()

              window.location.reload();

            }
            else if (_res.checkresult === 'EXISTDELIVERED') {
              this.toastr.warning('', "Already Deliver this Shipment");
              this.fnCloseDeliveryOrder();
            }
          }
          else if (res.json().status == 400) {
            this.fnHideProgress();
            this.toastr.error('Error', res.json().err_field);
          }
        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }

  fnSetUnDeliveredDeliveryOrder(deliveryorderid) {


    var data = {
      deliveryorderid: deliveryorderid,
      isdelivered: false,
      deliveredby: 0,
      modifiedby: this.loginService.fnGetModUserId(),
    }
    this.appService.fnSetDeliveryOrderDelivered(data)
      .subscribe(
        (res) => {

          if (res.json().status == 200) {
            var _res = res.json().result[0][0];
            if (_res.checkresult === 'DONE') {
              this.fnInsertActivityLogStatus('UNDELIVERED', deliveryorderid, this.fnGetDescription(deliveryorderid), this.fnGetOrderid(deliveryorderid), deliveryorderid);
              this.fnCloseDeliveryOrder();
            }
            else if (_res.checkresult === 'EXISTRECEIVED') {
              this.toastr.warning('', "Already Receive Amount this Shipment");
              this.fnCloseDeliveryOrder();
            }
          }
          else if (res.json().status == 400) {
            this.fnHideProgress();
            this.toastr.error('Error', res.json().err_field);
          }
        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }

  fnDeliveryOrderReceived() {
    this.confirmationDialogService.confirm('Confirm..', 'Do you  want to Received Shipment?')
      .then((confirmed) => {
        if (confirmed) {
          this.fnShowProgress();

          this.fnSetReceivedDeliveryOrder(this.deliveryorderid)

          this.fnHideProgress();
        }
      }
      );

  }

  fnSetReceivedDeliveryOrder(deliveryorderid) {


    var data = {
      deliveryorderid: deliveryorderid,
      isreceived: true,
      modifiedby: this.loginService.fnGetModUserId(),
    }
    this.appService.fnSetDeliveryOrderReceived(data)
      .subscribe(
        (res) => {

          if (res.json().status == 200) {
            this.fnCloseDeliveryOrder();
          }
          else if (res.json().status == 400) {
            this.fnHideProgress();
            this.toastr.error('Error', res.json().err_field);
          }
        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }

  fnSetUnReceivedDeliveryOrder(deliveryorderid) {


    var data = {
      deliveryorderid: deliveryorderid,
      isreceived: false,
      modifiedby: this.loginService.fnGetModUserId(),
    }
    this.appService.fnSetDeliveryOrderReceived(data)
      .subscribe(
        (res) => {

          if (res.json().status == 200) {
            this.fnCloseDeliveryOrder();
          }
          else if (res.json().status == 400) {
            this.fnHideProgress();
            this.toastr.error('Error', res.json().err_field);
          }
        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }


  //RevertStatus
  fnConfirmRevertStatus(type) {

    let msg = "";


    if (type === 'UnAssign') {
      msg = "Revert Assign Driver";
    }
    else if (type === 'UnReadyToPick') {
      msg = "Revert ReadyToPick";
    }
    else if (type === 'UnPick') {
      msg = "Revert Pick";
    }
    else if (type === 'UnDelivery') {
      msg = "Revert Delivery";
    }
    else if (type === 'UnReceive') {
      msg = "Revert Settlement";
    }


    this.confirmationDialogService.confirm('Confirm..', 'Do you  want to ' + msg + ' Shipment?')
      .then((confirmed) => {
        if (confirmed) {
          this.fnShowProgress();



          if (type === 'UnAssign') {
            this.fnSetUnAssignDeliveryOrder(this.deliveryorderid)
          }
          else if (type === 'UnReadyToPick') {
            this.fnSetUnReadyToPickDeliveryOrder(this.deliveryorderid)
          }
          else if (type === 'UnPick') {
            this.fnSetUnPickDeliveryOrder(this.deliveryorderid)
          }
          else if (type === 'UnDelivery') {
            this.fnSetUnDeliveredDeliveryOrder(this.deliveryorderid)
          }
          else if (type === 'UnReceive') {
            this.fnSetUnReceivedDeliveryOrder(this.deliveryorderid)
          }


          this.fnHideProgress();
        }
      }
      );

  }


  fnSendMailByOrder() {

    this.fnShowProgress();
    var data = {
      orderid: this.orderid,
      mailtype: 'DELIVERED',
    }
    this.appService.fnSendMailByOrder(data)
      .subscribe(
        (res) => {
          console.log(res.json());

          this.fnHideProgress();
          //this.fnSendNotificationByOrder('DELIVERED');                  
        },
        (err) => {
          console.log(err);
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }


  fnSendNotificationByOrder(type) {

    this.fnShowProgress();
    var data = {
      orderid: this.orderid,
      actiontype: type,
    }
    this.appService.fnSendNotificationByOrder(data)
      .subscribe(
        (res) => {
          this.fnHideProgress();
          if (res.json().status == 200) {
            this.fnCloseDeliveryOrder();
          }
          else if (res.json().status == 400) {
            this.fnHideProgress();
            this.toastr.error('Error', res.json().err_field);
          }
        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }



  //Firebase

  fnFirebaseSetAdminChanges() {
    // this.fireshipment.forEach(element => {             
    //   if(element.id==="ADMIN"){           
    //     if(element.ischange===1){
    //       this.fngetOrderListView();
    //     }
    //   }                   
    // })


    this.firestore.database.ref('ShipmentChanges/').child("ADMIN").set({ id: "ADMIN", ischange: 0 })
  }

  fnFirebaseSetDeliverBoyChanges(userid) {

    let _ischeck: boolean = false;
    let _idenid = ""


    this.fireshipment.forEach(element => {
      if (element.id === userid) {
        _idenid = element.idenid;
        _ischeck = true;
      }
    })

    if (_ischeck) {
      this.firestore.database.ref('ShipmentChanges/').child(_idenid).set({
        idenid: _idenid,
        id: userid,
        ischange: 1
      })
    }
    else {
      let bloglist: AngularFireList<any>;
      bloglist = this.firestore.list('/ShipmentChanges');
      var _odid = bloglist.query.ref.push();


      this.firestore.database.ref('ShipmentChanges/').child(_odid.key).set({
        idenid: _odid.key,
        id: userid,
        ischange: 1
      })

    }





  }



  //PRINT

  fnPrintInvoice() {

    const modalRef = this.productFindService.open(PrintorderComponent, { size: <any>'xl' });
    modalRef.componentInstance.doctype = "PRINT INVOICE";
    modalRef.componentInstance.orderid = this.orderid;
    modalRef.componentInstance.fnPrintOrderInvoice();

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

    });



  }


  fnPrintDOSlip() {

    const modalRef = this.productFindService.open(PrintorderComponent, { size: <any>'xl' });
    modalRef.componentInstance.doctype = "PRINT DOSLIP";
    modalRef.componentInstance.deliveryorderid = this.deliveryorderid;
    modalRef.componentInstance.fnPrintDeliveryOrderSlip();

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

    });



  }

  fnPrintOrderItem() {

    const modalRef = this.productFindService.open(PrintorderComponent, { size: <any>'xl' });
    modalRef.componentInstance.doctype = "PRINT PICKLIST";
    modalRef.componentInstance.orderid = this.orderid;
    modalRef.componentInstance.fnPrintPickList();

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
      // window.location.reload()      
    });



  }

  fnPrintOrder() {

    const modalRef = this.productFindService.open(PrintorderComponent, { size: <any>'xl' });
    modalRef.componentInstance.doctype = "PRINT ORDER";
    modalRef.componentInstance.orderid = this.orderid;
    modalRef.componentInstance.fnPrintOrder();

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
      // window.location.reload()      
    });


  }



  fnFormatDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        return this.datePipe.transform(dt, "yyyy-MM-dd")
      }

    } else {
      return null
    }

  }

  fnConvertDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {


        var dateParts = dt.split("-");

        var sdate = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2] + 1);

        sdate.setMinutes(sdate.getMinutes() + sdate.getTimezoneOffset());



        return sdate;
      }

    } else {
      return null
    }

  }




  fnGetOrderid(id) {

    return this.deliveryorderBO.orderid;


  }


  fnGetDescription(id) {

    var _desc = "";

    if (this.deliveryorderBO.orderno !== undefined) {
      _desc = this.deliveryorderBO.orderno
    }

    if (this.deliveryorderBO.deliveryorderno !== undefined) {
      _desc = _desc + "-" + this.deliveryorderBO.deliveryorderno
    }
    return _desc;


  }

  //Zoom Product
  fnShowZoomProduct(skuid) {
    const modalRef = this.productFindService.open(ZoomproductComponent, { size: <any>'l' },);
    modalRef.componentInstance.doctype = "";
    modalRef.componentInstance.skuid = skuid;
    modalRef.componentInstance.fnGetProductInfo();
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {


    });

  }

  // Order Option
  fnOrderOption() {
    const modalRef = this.productFindService.open(OrderoptionComponent, { size: <any>'l' });

    modalRef.componentInstance.doctype = 'SHIPMENT';
    modalRef.componentInstance.deliveryorderBO = this.deliveryorderBO;
    modalRef.componentInstance.isdeleteshipment = this.isdeleteshipment;
    modalRef.componentInstance.isorderreadytopick = this.isorderreadytopick;
    modalRef.componentInstance.isorderassign = this.isorderassign;
    modalRef.componentInstance.isorderpick = this.isorderpick;
    modalRef.componentInstance.isorderdeliver = this.isorderdeliver;
    modalRef.componentInstance.isorderreceived = this.isorderreceived;
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

      if (receivedEntry === 'REVERTSHIPMENT') {
        this.fnDeleteConfirm(this.deliveryorderid);
      }
      else if (receivedEntry === 'UNREADYTOPICK') {
        this.fnConfirmRevertStatus('UnReadyToPick');
      }
      else if (receivedEntry === 'ASSIGN') {
        this.fnDeliveryOrderAssigned();
      }
      else if (receivedEntry === 'UNASSIGN') {
        this.fnConfirmRevertStatus('UnAssign');
      }
      else if (receivedEntry === 'PICK') {
        this.fnDeliveryOrderPicked();
      }
      else if (receivedEntry === 'UNPICK') {
        this.fnConfirmRevertStatus('UnPick');
      }
      else if (receivedEntry === 'DELIVER') {
        this.fnDeliveryOrderDelivered();
      }
      else if (receivedEntry === 'UNDELIVER') {
        this.fnConfirmRevertStatus('UnDelivery');
      }
      else if (receivedEntry === 'RECEIVED') {
        this.fnSetReceivedDeliveryOrder(this.deliveryorderid);
      }
      else if (receivedEntry === 'UNRECEIVED') {
        this.fnSetUnReceivedDeliveryOrder(this.deliveryorderid);
      }

    });

  }


  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
        orderid: this.orderid,
        deliveryorderid: this.deliveryorderid,
      }


      this.appService.fnInsertActivity(data).subscribe(
        (res) => {
        },
        (err) => {

        });

    } catch {

    }
  }


  fnInsertActivityLogStatus(action, refno, desc, orderid, deliveryorderid) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
        orderid: orderid,
        deliveryorderid: deliveryorderid
      }


      this.appService.fnInsertActivity(data).subscribe(
        (res) => {
        },
        (err) => {

        });

    } catch {

    }
  }



  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }



  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }




}
